/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getFAQ = /* GraphQL */ `
  query GetFAQ($id: ID!) {
    getFAQ(id: $id) {
      id
      question
      answer
      otherExtra
      createdAt
      updatedAt
    }
  }
`;
export const listFAQS = /* GraphQL */ `
  query ListFAQS(
    $filter: ModelFAQFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFAQS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        question
        answer
        otherExtra
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPolicy = /* GraphQL */ `
  query GetPolicy($id: ID!) {
    getPolicy(id: $id) {
      id
      title
      descriptionLines
      otherExtra
      createdAt
      updatedAt
    }
  }
`;
export const listPolicies = /* GraphQL */ `
  query ListPolicies(
    $filter: ModelPolicyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPolicies(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        descriptionLines
        otherExtra
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getStaticPackage = /* GraphQL */ `
  query GetStaticPackage($id: ID!) {
    getStaticPackage(id: $id) {
      id
      title
      duration
      description
      itinerary
      pricing
      locations
      addOns
      inclusions
      exclusions
      terms
      tags
      isActive
      isApproved
      approvedBy
      destinationId
      createdBy
      otherExtra
      multilineDesc
      isMultilineDesc
      createdAt
      updatedAt
    }
  }
`;
export const listStaticPackages = /* GraphQL */ `
  query ListStaticPackages(
    $filter: ModelStaticPackageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStaticPackages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        duration
        description
        itinerary
        pricing
        locations
        addOns
        inclusions
        exclusions
        terms
        tags
        isActive
        isApproved
        approvedBy
        destinationId
        createdBy
        otherExtra
        multilineDesc
        isMultilineDesc
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLeadPackage = /* GraphQL */ `
  query GetLeadPackage($id: ID!) {
    getLeadPackage(id: $id) {
      id
      title
      duration
      tripSummary
      tripCordinator
      itinerary
      totalPrice
      locations
      paymentOptions
      inclusions
      exclusions
      extraInfo
      terms
      isActive
      isApproved
      approvedBy
      destinationId
      leadId
      createdBy
      otherExtra
      createdAt
      updatedAt
    }
  }
`;
export const listLeadPackages = /* GraphQL */ `
  query ListLeadPackages(
    $filter: ModelLeadPackageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeadPackages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        duration
        tripSummary
        tripCordinator
        itinerary
        totalPrice
        locations
        paymentOptions
        inclusions
        exclusions
        extraInfo
        terms
        isActive
        isApproved
        approvedBy
        destinationId
        leadId
        createdBy
        otherExtra
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDestination = /* GraphQL */ `
  query GetDestination($id: ID!) {
    getDestination(id: $id) {
      id
      name
      country
      cities
      places
      description
      photos
      banners
      terms
      notices
      otherExtra
      staticPackages {
        items {
          id
          title
          duration
          description
          itinerary
          pricing
          locations
          addOns
          inclusions
          exclusions
          terms
          tags
          isActive
          isApproved
          approvedBy
          destinationId
          createdBy
          otherExtra
          multilineDesc
          isMultilineDesc
          createdAt
          updatedAt
        }
        nextToken
      }
      leadPackages {
        items {
          id
          title
          duration
          tripSummary
          tripCordinator
          itinerary
          totalPrice
          locations
          paymentOptions
          inclusions
          exclusions
          extraInfo
          terms
          isActive
          isApproved
          approvedBy
          destinationId
          leadId
          createdBy
          otherExtra
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listDestinations = /* GraphQL */ `
  query ListDestinations(
    $filter: ModelDestinationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDestinations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        country
        cities
        places
        description
        photos
        banners
        terms
        notices
        otherExtra
        staticPackages {
          nextToken
        }
        leadPackages {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEmployee = /* GraphQL */ `
  query GetEmployee($id: ID!) {
    getEmployee(id: $id) {
      id
      name
      department
      mobile
      altMobile
      address
      birthdate
      joiningDate
      gender
      isActive
      email
      profilePic
      roleId
      emergencyContactName
      emergencyContactNumber
      otherExtra
      createdAt
      updatedAt
    }
  }
`;
export const listEmployees = /* GraphQL */ `
  query ListEmployees(
    $filter: ModelEmployeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmployees(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        department
        mobile
        altMobile
        address
        birthdate
        joiningDate
        gender
        isActive
        email
        profilePic
        roleId
        emergencyContactName
        emergencyContactNumber
        otherExtra
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRole = /* GraphQL */ `
  query GetRole($id: ID!) {
    getRole(id: $id) {
      id
      name
      permissions
      isAdmin
      employees {
        items {
          id
          name
          department
          mobile
          altMobile
          address
          birthdate
          joiningDate
          gender
          isActive
          email
          profilePic
          roleId
          emergencyContactName
          emergencyContactNumber
          otherExtra
          createdAt
          updatedAt
        }
        nextToken
      }
      description
      otherExtra
      createdAt
      updatedAt
    }
  }
`;
export const listRoles = /* GraphQL */ `
  query ListRoles(
    $filter: ModelRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        permissions
        isAdmin
        employees {
          nextToken
        }
        description
        otherExtra
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDMCAccount = /* GraphQL */ `
  query GetDMCAccount($id: ID!) {
    getDMCAccount(id: $id) {
      id
      name
      contacts
      address
      isActive
      bankDetails
      destinationId
      description
      otherExtra
      createdAt
      updatedAt
    }
  }
`;
export const listDMCAccounts = /* GraphQL */ `
  query ListDMCAccounts(
    $filter: ModelDMCAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDMCAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        contacts
        address
        isActive
        bankDetails
        destinationId
        description
        otherExtra
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLead = /* GraphQL */ `
  query GetLead($id: ID!) {
    getLead(id: $id) {
      id
      name
      email
      mobile
      altMobile
      city
      travelDate
      destination
      adults
      children
      description
      schedule
      followUps
      status
      isActive
      otherExtra
      assignedTo
      assignedBy
      leadPackages {
        items {
          id
          title
          duration
          tripSummary
          tripCordinator
          itinerary
          totalPrice
          locations
          paymentOptions
          inclusions
          exclusions
          extraInfo
          terms
          isActive
          isApproved
          approvedBy
          destinationId
          leadId
          createdBy
          otherExtra
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listLeads = /* GraphQL */ `
  query ListLeads(
    $filter: ModelLeadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeads(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        mobile
        altMobile
        city
        travelDate
        destination
        adults
        children
        description
        schedule
        followUps
        status
        isActive
        otherExtra
        assignedTo
        assignedBy
        leadPackages {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getViaOrder = /* GraphQL */ `
  query GetViaOrder($id: ID!) {
    getViaOrder(id: $id) {
      id
      reference
      order_ref_attr
      order_reference_attr
      ticketUrl
      isFlightBooked
      payments {
        items {
          id
          transactionId
          amount
          status
          channel
          type
          orderId
          optionId
          otherExtra
          createdBy
          createdAt
          updatedAt
        }
        nextToken
      }
      otherExtra
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const listViaOrders = /* GraphQL */ `
  query ListViaOrders(
    $filter: ModelViaOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listViaOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        reference
        order_ref_attr
        order_reference_attr
        ticketUrl
        isFlightBooked
        payments {
          nextToken
        }
        otherExtra
        createdBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTripOrder = /* GraphQL */ `
  query GetTripOrder($id: ID!) {
    getTripOrder(id: $id) {
      id
      totalAmount
      isPaymentComplete
      leadId
      packageId
      tripRef
      isPaymentOption
      paymentOptions
      prevPayOptions
      payments {
        items {
          id
          transactionId
          amount
          status
          channel
          type
          orderId
          optionId
          otherExtra
          createdBy
          createdAt
          updatedAt
        }
        nextToken
      }
      otherExtra
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const listTripOrders = /* GraphQL */ `
  query ListTripOrders(
    $filter: ModelTripOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTripOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        totalAmount
        isPaymentComplete
        leadId
        packageId
        tripRef
        isPaymentOption
        paymentOptions
        prevPayOptions
        payments {
          nextToken
        }
        otherExtra
        createdBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPayment = /* GraphQL */ `
  query GetPayment($id: ID!) {
    getPayment(id: $id) {
      id
      transactionId
      amount
      status
      channel
      type
      orderId
      optionId
      otherExtra
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const listPayments = /* GraphQL */ `
  query ListPayments(
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        transactionId
        amount
        status
        channel
        type
        orderId
        optionId
        otherExtra
        createdBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVoucher = /* GraphQL */ `
  query GetVoucher($id: ID!) {
    getVoucher(id: $id) {
      id
      orderId
      summary
      hotels
      flights
      otherDocs
      guests
      cordinators
      otherExtra
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const listVouchers = /* GraphQL */ `
  query ListVouchers(
    $filter: ModelVoucherFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVouchers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orderId
        summary
        hotels
        flights
        otherDocs
        guests
        cordinators
        otherExtra
        createdBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCompanyInfo = /* GraphQL */ `
  query GetCompanyInfo($id: ID!) {
    getCompanyInfo(id: $id) {
      id
      legalInfo
      bankingInfo
      contacts
      websiteManagement
      currentPackageInfo
      otherExtra
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const listCompanyInfos = /* GraphQL */ `
  query ListCompanyInfos(
    $filter: ModelCompanyInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanyInfos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        legalInfo
        bankingInfo
        contacts
        websiteManagement
        currentPackageInfo
        otherExtra
        createdBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

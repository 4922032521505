import {
	apiRequest,
	updateAccommodations,
	updateNotification,
	updateSpinner,
} from "../actions";
import {
	closeCreateAccommodationDrawer,
	closeUpdateAccommodationDrawer,
} from "../actions/accommodation";
import {
	CREATE_ACCOMMODATION,
	CREATE_ACCOMMODATION_SUCCESS,
	DELETE_ACCOMMODATION,
	DELETE_ACCOMMODATION_SUCCESS,
	GET_ACCOMMODATION,
	GET_ACCOMMODATIONS,
	GET_ACCOMMODATIONS_SUCCESS,
	GET_ACCOMMODATION_SUCCESS,
	UPDATE_ACCOMMODATION,
	UPDATE_ACCOMMODATION_SUCCESS,
	UPDATE_NOTIFICATION_API,
} from "../constants";

const URL = "https://jsonplaceholder.typicode.com/posts";

// -------------------- Get and Proccess All accommodations -----------------------------------------------------------------
export const getAccommodationsFlow =
	({ dispatch }) =>
	(next) =>
	(action) => {
		next(action);
		if (action.type === GET_ACCOMMODATIONS) {
			dispatch(
				updateSpinner({ show: true, message: "Loading Accommodations . . . " })
			);
			dispatch(
				apiRequest(
					"GET",
					URL,
					null,
					GET_ACCOMMODATIONS_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
		if (action.type === GET_ACCOMMODATION) {
			let newURL = `${URL}/${action.payload}`;
			dispatch(
				updateSpinner({ show: true, message: "Loading Accommodation . . . " })
			);
			dispatch(
				apiRequest(
					"GET",
					newURL,
					null,
					GET_ACCOMMODATION_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
		if (action.type === CREATE_ACCOMMODATION) {
			dispatch(
				updateSpinner({
					show: true,
					message: "Creating New Accommodation . . . ",
				})
			);
			dispatch(
				apiRequest(
					"POST",
					URL,
					action.payload,
					CREATE_ACCOMMODATION_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
		if (action.type === UPDATE_ACCOMMODATION) {
			let newURL = `${URL}/${action.payload.id}`;
			dispatch(
				updateSpinner({ show: true, message: "Updating Accommodation . . . " })
			);
			dispatch(
				apiRequest(
					"PATCH",
					newURL,
					action.payload,
					UPDATE_ACCOMMODATION_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
		if (action.type === DELETE_ACCOMMODATION) {
			let newURL = `${URL}/${action.payload.id}`;
			console.log("Id to delete", action.payload.id);
			dispatch(
				updateSpinner({ show: true, message: "Deleting Accommodation . . . " })
			);
			dispatch(
				apiRequest(
					"DELETE",
					newURL,
					action.payload,
					DELETE_ACCOMMODATION_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
	};
export const processAccommodationsFlow =
	({ dispatch, getState }) =>
	(next) =>
	(action) => {
		next(action);
		if (action.type === GET_ACCOMMODATIONS_SUCCESS) {
			dispatch(updateAccommodations({ accommodations: action.payload }));
			dispatch(updateSpinner({ show: false, message: "" }));
		}

		if (action.type === GET_ACCOMMODATION_SUCCESS) {
			dispatch(updateAccommodations({ selectedAccommodation: action.payload }));
			dispatch(updateSpinner({ show: false, message: "" }));
		}
		if (action.type === CREATE_ACCOMMODATION_SUCCESS) {
			let accommodations = getState().accommodation.accommodations;
			accommodations.push(action.payload);
			dispatch(updateAccommodations({ accommodations }));
			dispatch(closeCreateAccommodationDrawer());
			dispatch(updateSpinner({ show: false, message: "" }));
			dispatch(
				updateNotification({
					type: "success",
					message: "Success",
					description: "Accommodation Created Cuccessfully",
				})
			);
		}

		if (action.type === UPDATE_ACCOMMODATION_SUCCESS) {
			let accommodations = getState().accommodation.accommodations;
			let updatedAccommodations = accommodations.map((el) => {
				if (el.id === action.payload.id) {
					return action.payload;
				}
				return el;
			});
			dispatch(updateAccommodations({ accommodations: updatedAccommodations }));
			dispatch(closeUpdateAccommodationDrawer());
			dispatch(updateSpinner({ show: false, message: "" }));
			dispatch(
				updateNotification({
					type: "info",
					message: "Information",
					description: "Accommodation Updated",
				})
			);
		}

		if (action.type === DELETE_ACCOMMODATION_SUCCESS) {
			let accommodations = getState().accommodation.accommodations;
			let updatedAccommodations = accommodations.filter(
				(el) => el.id !== action.deleteId
			);
			dispatch(updateAccommodations({ accommodations: updatedAccommodations }));
			dispatch(closeUpdateAccommodationDrawer());
			dispatch(updateSpinner({ show: false, message: "" }));
			dispatch(
				updateNotification({
					type: "info",
					message: "Information",
					description: "Accommodation Deleted",
				})
			);
		}
	};
// ---------------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------

export const accommodationMdl = [
	getAccommodationsFlow,
	processAccommodationsFlow,
];

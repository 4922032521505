import {
	apiRequest,
	updateNotification,
	updateSpinner,
	updateUsers,
} from "../actions";
import { closeCreateUserDrawer, closeUpdateUserDrawer } from "../actions/user";
import {
	CREATE_USER,
	CREATE_USER_SUCCESS,
	DELETE_USER,
	DELETE_USER_SUCCESS,
	GET_USER,
	GET_USERS,
	GET_USERS_SUCCESS,
	GET_USER_SUCCESS,
	UPDATE_NOTIFICATION_API,
	UPDATE_USER,
	UPDATE_USER_SUCCESS,
} from "../constants";

const URL = "https://jsonplaceholder.typicode.com/posts";

// -------------------- Get and Proccess All Users -----------------------------------------------------------------
export const getUsersFlow =
	({ dispatch }) =>
	(next) =>
	(action) => {
		next(action);
		if (action.type === GET_USERS) {
			dispatch(updateSpinner({ show: true, message: "Loading Users . . . " }));
			dispatch(
				apiRequest("GET", URL, null, GET_USERS_SUCCESS, UPDATE_NOTIFICATION_API)
			);
		}
		if (action.type === GET_USER) {
			let newURL = `${URL}/${action.payload}`;
			dispatch(updateSpinner({ show: true, message: "Loading User . . . " }));
			dispatch(
				apiRequest(
					"GET",
					newURL,
					null,
					GET_USER_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
		if (action.type === CREATE_USER) {
			dispatch(
				updateSpinner({
					show: true,
					message: "Creating New User . . . ",
				})
			);
			dispatch(
				apiRequest(
					"POST",
					URL,
					action.payload,
					CREATE_USER_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
		if (action.type === UPDATE_USER) {
			let newURL = `${URL}/${action.payload.id}`;
			dispatch(updateSpinner({ show: true, message: "Updating User . . . " }));
			dispatch(
				apiRequest(
					"PATCH",
					newURL,
					action.payload,
					UPDATE_USER_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
		if (action.type === DELETE_USER) {
			let newURL = `${URL}/${action.payload.id}`;
			console.log("Id to delete", action.payload.id);
			dispatch(updateSpinner({ show: true, message: "Deleting User . . . " }));
			dispatch(
				apiRequest(
					"DELETE",
					newURL,
					action.payload,
					DELETE_USER_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
	};
export const processUsersFlow =
	({ dispatch, getState }) =>
	(next) =>
	(action) => {
		next(action);
		if (action.type === GET_USERS_SUCCESS) {
			dispatch(updateUsers({ users: action.payload }));
			dispatch(updateSpinner({ show: false, message: "" }));
		}

		if (action.type === GET_USER_SUCCESS) {
			dispatch(updateUsers({ selectedUser: action.payload }));
			dispatch(updateSpinner({ show: false, message: "" }));
		}
		if (action.type === CREATE_USER_SUCCESS) {
			let Users = getState().User.Users;
			Users.push(action.payload);
			dispatch(updateUsers({ Users }));
			dispatch(closeCreateUserDrawer());
			dispatch(updateSpinner({ show: false, message: "" }));
			dispatch(
				updateNotification({
					type: "success",
					message: "Success",
					description: "User Created Cuccessfully",
				})
			);
		}

		if (action.type === UPDATE_USER_SUCCESS) {
			let Users = getState().User.Users;
			let updatedUsers = Users.map((el) => {
				if (el.id === action.payload.id) {
					return action.payload;
				}
				return el;
			});
			dispatch(updateUsers({ users: updatedUsers }));
			dispatch(closeUpdateUserDrawer());
			dispatch(updateSpinner({ show: false, message: "" }));
			dispatch(
				updateNotification({
					type: "info",
					message: "Information",
					description: "User Updated",
				})
			);
		}

		if (action.type === DELETE_USER_SUCCESS) {
			let Users = getState().User.Users;
			let updatedUsers = Users.filter((el) => el.id !== action.deleteId);
			dispatch(updateUsers({ users: updatedUsers }));
			dispatch(closeUpdateUserDrawer());
			dispatch(updateSpinner({ show: false, message: "" }));
			dispatch(
				updateNotification({
					type: "info",
					message: "Information",
					description: "User Deleted",
				})
			);
		}
	};
// ---------------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------

export const userMdl = [getUsersFlow, processUsersFlow];

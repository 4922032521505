import {
	apiRequest,
	listRequest,
	updateRequest,
	getSingleRequest,
	updateNotification,
	updateSpinner,
	updateDestinations,
} from "../actions";
import {
	closeCreateDestinationDrawer,
	closeUpdateDestinationDrawer,
} from "../actions/destination";
import {
	CREATE_DESTINATION,
	CREATE_DESTINATION_SUCCESS,
	DELETE_DESTINATION,
	DELETE_DESTINATION_SUCCESS,
	GET_DESTINATION,
	GET_DESTINATIONS,
	GET_DESTINATIONS_SUCCESS,
	GET_DESTINATION_SUCCESS,
	UPDATE_NOTIFICATION_API,
	UPDATE_DESTINATION,
	UPDATE_DESTINATION_SUCCESS,
} from "../constants";

const URL = "https://jsonplaceholder.typicode.com/posts";

// -------------------- Get and Proccess All Destinations -----------------------------------------------------------------
export const getDestinationsFlow =
	({ dispatch }) =>
	(next) =>
	(action) => {
		next(action);
		if (action.type === GET_DESTINATIONS) {
			dispatch(
				updateSpinner({ show: true, message: "Loading Destinations . . . " })
			);

			dispatch(
				listRequest(
					"listDestinations",
					null,
					GET_DESTINATIONS_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
		if (action.type === GET_DESTINATION) {
			dispatch(
				updateSpinner({ show: true, message: "Loading Destination . . . " })
			);

			dispatch(
				getSingleRequest(
					"getDestination",
					action.payload,
					GET_DESTINATION_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
		if (action.type === CREATE_DESTINATION) {
			dispatch(
				updateSpinner({
					show: true,
					message: "Creating New Destination . . . ",
				})
			);

			console.log("Adding Destination ", action.payload);

			dispatch(
				updateRequest(
					"createDestination",
					action.payload,
					CREATE_DESTINATION_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
		if (action.type === UPDATE_DESTINATION) {
			dispatch(
				updateSpinner({ show: true, message: "Updating Destination . . . " })
			);

			dispatch(
				updateRequest(
					"updateDestination",
					action.payload,
					UPDATE_DESTINATION_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
		if (action.type === DELETE_DESTINATION) {
			let newURL = `${URL}/${action.payload.id}`;
			console.log("Id to delete", action.payload.id);
			dispatch(
				updateSpinner({ show: true, message: "Deleting Destination . . . " })
			);
			dispatch(
				apiRequest(
					"DELETE",
					newURL,
					action.payload,
					DELETE_DESTINATION_SUCCESS,
					UPDATE_NOTIFICATION_API
				)
			);
		}
	};
export const processDestinationsFlow =
	({ dispatch, getState }) =>
	(next) =>
	(action) => {
		next(action);
		if (action.type === GET_DESTINATIONS_SUCCESS) {
			dispatch(updateDestinations({ destinations: action.payload }));
			dispatch(updateSpinner({ show: false, message: "" }));
		}

		if (action.type === GET_DESTINATION_SUCCESS) {
			dispatch(updateDestinations({ selectedDestination: action.payload }));
			dispatch(updateSpinner({ show: false, message: "" }));
		}
		if (action.type === CREATE_DESTINATION_SUCCESS) {
			let destinations = getState().destination.destinations;
			destinations.push(action.payload);
			dispatch(updateDestinations({ destinations }));
			dispatch(closeCreateDestinationDrawer());
			dispatch(updateSpinner({ show: false, message: "" }));
			dispatch(
				updateNotification({
					type: "success",
					message: "Success",
					description: "Destination Created Cuccessfully",
				})
			);
		}

		if (action.type === UPDATE_DESTINATION_SUCCESS) {
			let destinations = getState().destination.destinations;
			let updatedDestinations = destinations.map((el) => {
				if (el.id === action.payload.id) {
					return action.payload;
				}
				return el;
			});
			dispatch(updateDestinations({ destinations: updatedDestinations }));
			dispatch(closeUpdateDestinationDrawer());
			dispatch(updateSpinner({ show: false, message: "" }));
			dispatch(
				updateNotification({
					type: "info",
					message: "Information",
					description: "Destination Details Updated",
				})
			);
		}

		if (action.type === DELETE_DESTINATION_SUCCESS) {
			let destinations = getState().destination.destinations;
			let updatedDestinations = destinations.filter(
				(el) => el.id !== action.deleteId
			);
			dispatch(updateDestinations({ destinations: updatedDestinations }));
			dispatch(closeUpdateDestinationDrawer());
			dispatch(updateSpinner({ show: false, message: "" }));
			dispatch(
				updateNotification({
					type: "info",
					message: "Information",
					description: "Destination Deleted",
				})
			);
		}
	};
// ---------------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------

export const destinationMdl = [getDestinationsFlow, processDestinationsFlow];

import { Amplify, API, graphqlOperation } from "aws-amplify";
import {
	GET_LIST_QUERY,
	GET_SINGLE_QUERY,
	UPDATE_MUTATION,
} from "../constants";
import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";

// This middleware care only for API calls
export const GET_LIST =
	({ dispatch }) =>
	(next) =>
	async (action) => {
		if (action.type === GET_LIST_QUERY) {
			const { payload } = action;
			const { method, onSuccess, onError } = action.meta;
			const id = method === "DELETE" ? payload.id : null;

			try {
				const Data = await API.graphql(graphqlOperation(queries[method]));
				dispatch({
					type: onSuccess,
					payload: Data?.data[method]?.items || [],
					deleteId: id,
				});
			} catch (error) {
				const [a, b, ...c] = onSuccess.split(" ")[1].split("_");
				let errorMessage = `${a} ${b} ERROR`;
				dispatch({
					type: onError,
					payload: {
						type: "error",
						message: errorMessage || "Error",
						description: error.message || errorMessage,
					},
				});
			}
		}
		return next(action);
	};

// This middleware care only for API calls
export const GET_SINGLE =
	({ dispatch }) =>
	(next) =>
	async (action) => {
		if (action.type === GET_SINGLE_QUERY) {
			const { payload } = action;
			const { method, onSuccess, onError } = action.meta;
			const id = method === "DELETE" ? payload.id : null;

			try {
				const Data = await API.graphql(
					graphqlOperation(queries[method], {
						id: payload,
					})
				);
				dispatch({
					type: onSuccess,
					payload: Data?.data[method] || null,
					deleteId: id,
				});
			} catch (error) {
				const [a, b, ...c] = onSuccess.split(" ")[1].split("_");
				let errorMessage = `${a} ${b} ERROR`;
				dispatch({
					type: onError,
					payload: {
						type: "error",
						message: errorMessage || "Error",
						description: error.message || errorMessage,
					},
				});
			}
		}
		return next(action);
	};
// This middleware care only for API calls
export const UPDATE_DATA =
	({ dispatch }) =>
	(next) =>
	async (action) => {
		if (action.type === UPDATE_MUTATION) {
			const { payload } = action;
			const { method, onSuccess, onError } = action.meta;
			const id = method === "DELETE" ? payload.id : null;
			console.log("UpDate Mutation Data", payload);

			try {
				const Data = await API.graphql(
					graphqlOperation(mutations[method], {
						input: payload,
					})
				);
				console.log("UpDate Mutation Response", Data);

				dispatch({
					type: onSuccess,
					payload: Data?.data[method] || null,
					deleteId: id,
				});
			} catch (error) {
				console.log("UpDate Mutation Error Response", error);

				const [a, b, ...c] = onSuccess.split(" ")[1].split("_");
				let errorMessage = `${a} ${b} ERROR`;
				dispatch({
					type: onError,
					payload: {
						type: "error",
						message: errorMessage || "Error",
						description: error.message || errorMessage,
					},
				});
			}
		}
		return next(action);
	};

export const graphqlMdl = [GET_LIST, GET_SINGLE, UPDATE_DATA];

// const str = "saurabh";
// let max = -1;
// let char = -1;
// let resObj = str.split("").reduce((acc, item) => {
// 	return { ...acc, [item]: acc[item] !== undefined ? acc[item] + 1 : 1 };
// }, {});

// Object.keys(resObj).forEach((key) => {
// 	let val = resObj[key];
// 	if (val > max) {
// 		max = val;
// 		char = key;
// 	}
// });
// console.log(char, max);

// const str = "saurabh";
// let max = -1,
// 	char = -1,
// 	resObj = str
// 		.split("")
// 		.reduce((r, a) => ({ ...r, [a]: void 0 !== r[a] ? r[a] + 1 : 1 }), {});
// Object.keys(resObj).forEach(
// 	(r) => resObj[r] > max && ((max = resObj[r]), (char = r))
// ),
// 	console.log(char, max);
// console.log(resObj);
